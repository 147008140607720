import { AxiosProgressEvent } from "axios";
import { constants } from "../constants/constants";
import { dataStore } from "../stores/dataStore";
import { localStore } from "../stores/localStore";
import { RatingObject } from "../apis/getInterviewDetails";
import parse from "html-react-parser";

export const asyncWrap = async <T extends Promise<any>>(
  promise: T
): Promise<[any, Awaited<T> | null]> => {
  try {
    const data = await promise;
    return [null, data];
  } catch (error) {
    return [error, null];
  }
};

export const joinClass = (...classes: Array<any>) => {
  return classes.join(" ");
};

export const getSupportedVideoMime = () => {
  const mimeTypes = ["video/webm", "video/mp4"];
  const supportedMime = mimeTypes.filter((x) =>
    MediaRecorder.isTypeSupported(x)
  )?.[0];
  return {
    supportedVideoType: supportedMime,
    supportedVideoExtension: supportedMime
      ? `${supportedMime.substring(supportedMime.lastIndexOf("/") + 1)}`
      : "",
  };
};

export const getSupportedAudioMime = () => {
  const mimeTypes = ["audio/webm", "audio/mp4"];
  const supportedMime = mimeTypes.filter((x) =>
    MediaRecorder.isTypeSupported(x)
  )?.[0];
  return {
    supportedAudioType: supportedMime,
    supportedAudioExtension: supportedMime
      ? `${supportedMime.substring(supportedMime.lastIndexOf("/") + 1)}`
      : "",
  };
};

export const setUserRole = (role: keyof typeof constants.userRoles) => {
  dataStore.auth.updateValue({ role });
  localStore.auth.updateValue({ role });
}

export const getMyRole = () => {
  return dataStore.auth.getValue()?.role || localStore.auth.getValue()?.role;
}

export const getAuthToken = () => {
  return dataStore.auth.getValue()?.token || localStore.auth.getValue()?.token;
}

export const getUserProfile = () => {
  return dataStore.userProfile.getValue() || localStore.userProfile.getValue();
}

export const appendLeadingZero = (num: number) => num < 10 ? `0${num}` : `${num}`;

const convertToSearchParamObject = (params: URLSearchParams): { [key: string]: any } => {
  const size = Array.from(params.keys()).length;
  if (!size) return {};
  return Array.from(params.entries()).reduce((acc, pair) => {
    const [param, value] = pair;
    const decodedValue = JSON.parse(decodeURIComponent(value));
    return { ...acc, [param]: decodedValue };
  }, {});
}

export const decodeParamsFromURI = (url: string) => {
  const queryString = url.split("?")[1];

  const dataParam = new URLSearchParams(queryString);
  return convertToSearchParamObject(dataParam);
};

export const shouldRevalidateWithSearchParams = (currentUrl: URL, nextUrl: URL, ignoreList: string[]) => {
  const currentUrlParams = convertToSearchParamObject(currentUrl.searchParams);
  return Array.from(nextUrl.searchParams.entries()).some(([key, value]) => {
    if (ignoreList.includes(key)) return false;
    const decodedValue = JSON.parse(decodeURIComponent(value));
    if (!currentUrlParams?.[key]) return !!decodedValue;
    return JSON.stringify(currentUrlParams?.[key]) !== JSON.stringify(decodedValue);
  })
}

export const getProgressFromAxios = (progress: AxiosProgressEvent, startTime: number = Date.now()) => {
  const loaded = progress.loaded ?? 0;
  const total = progress?.total ?? 0;
  const currentTime = Date.now();
  const timeDiff = (currentTime - startTime) / 1000;
  const uploadedInKb = (loaded / timeDiff) / 1000;
  const uploadedInMb = uploadedInKb >= 1000 ? uploadedInKb / 1000 : null;
  const uploaded = uploadedInMb ?? uploadedInKb;
  const speed = (uploaded).toFixed(2);
  return (
    {
      progressPercentage: Math.round((progress.loaded / total) * 100),
      speed: `${speed} ${uploadedInMb ? 'mb' : 'kb'}/s`
    }
  )
};

export const removeUnderscore = (word: string) => {
  const test1 = word.replaceAll("_", " ");
  return test1.charAt(0).toUpperCase() + test1.slice(1);
};

export const ellipsisText = (text: string = "", cutoffLength: number) => {
  const displayFileName =
    text?.length > cutoffLength ? "..." + text?.slice(-cutoffLength) : text;
  return displayFileName;
};

const pattern = /\/([^/]+)$/;
export const getFileNamefromURL = (url: string) => url && pattern.exec(url)![1];

export const getNameInitials = (name: string) => {
  const words = name.split(' ');
  return words.map((word) => word.charAt(0)).join("");
}

export const calculateOverallProgress = (data: any) => {
  let totalProgress = 0;
  let totalCount = 0;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const file = data[key];
      const { progressPercentage } = file;
      totalProgress += progressPercentage;
      totalCount++;
    }
  }
  const overallProgressPercentage = totalCount > 0 ? totalProgress / totalCount : 0;

  return overallProgressPercentage;
};

export const formatDisplayValue = (score: number | null) => {
  if (score !== null && typeof score === 'number') {
    const formattedScore = score.toFixed(2).padStart(5, '0');
    return formattedScore + "%";
  }
  return "Not Provided";
};

export const normalizeYearsFormat = (inputString: string) => {
  const match = inputString.match(/\d+/);

  if (match) {
    const number = parseInt(match[0], 10);
    return number === 1 ? `${number} year` : `${number} years`;
  }
  return "N/A";
}

export const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, key) => acc && acc[key], obj);
};

export const tableSorter = (data: any, label: string, isSorted: boolean) => {
  const sortedData = [...data];

  sortedData.sort((a, b) => {
    const valueA = getNestedValue(a, label);
    const valueB = getNestedValue(b, label);

    if (valueA == null || valueB == null) return 0;

    const valueAStr = typeof valueA === 'number' ? valueA : valueA.toString().toUpperCase();
    const valueBStr = typeof valueB === 'number' ? valueB : valueB.toString().toUpperCase();

    if (valueAStr > valueBStr) return isSorted ? -1 : 1;
    if (valueAStr < valueBStr) return isSorted ? 1 : -1;
    return 0;
  });

  return sortedData;
};


/**
 * Utility function to download a file.
 * @param fileData - The data of the file to download, which can be a Blob or a URL string.
 * @param fileName - The name to give the downloaded file.
 */
export const downloadFile = (fileData: Blob | string, fileName?: string) => {
  let url;
  if (fileData instanceof Blob) {
    url = URL.createObjectURL(fileData);
  } else if (typeof fileData === "string") {
    url = fileData;
  } else {
    console.error("Invalid fileData type. Expected Blob or string.");
    return;
  }

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName ?? "Screener-File";
  document.body.appendChild(a);
  a.click();

  if (fileData instanceof Blob) {
    URL.revokeObjectURL(url);
  }
  document.body.removeChild(a);
};


export const getScoreReason = (
  score: RatingObject | null,
  criterion: string,
  parseHTML = false
) => {
  const reason = score?.[criterion]?.reason || "N/A";
  return parseHTML ? parse(reason) : reason;
};

export const getScoreRating = (score: RatingObject | null, criterion: string) =>
  score?.[criterion]?.rating || 0;



/**
 * Checks if a specified element is currently in fullscreen mode.
 *
 * This function determines if the current fullscreen element matches the provided element.
 * It logs the result to the console and returns a boolean indicating
 * whether the fullscreen element matches the provided element.
 *
 * @param {HTMLElement} element - The element to check for fullscreen mode.
 * @returns {boolean} `true` if the fullscreen element matches the provided element, otherwise `false`.
 */
export const isElementInFullscreen = (element: HTMLElement): boolean => {
  const fullscreen = document.fullscreenElement === element;
  return fullscreen;
};