import { getSupportedAudioMime, getSupportedVideoMime } from "../utils/helper";

export const constants = {
  userRoles: {
    CANDIDATE: 'CANDIDATE' as const,
    ADMIN: 'ADMIN' as const,
    HR: 'HR' as const
  },
  ...getSupportedVideoMime(),
  ...getSupportedAudioMime()
}

export const interviewStatuses = {
  CREATED: "0",
  SHORTLISTED: "1",
  INVITED: "2",
  SCHEDULED: "3",
  RESCHEDULED: "4",
  CANCELLED: "5",
  IN_PROGRESS: "6",
  COMPLETED: "7",
  EVALUATED: "8",
  ON_HOLD: "9",
  INTERRUPTED: "10",
};

export const uploadConstraints = {
  size: 1024 * 1024,
  format: ".csv"
};


export enum TriggerEventType {
  TabChange = "TAB_CHANGE",
  Reload = "RELOAD",
  ButtonClick = "BUTTON_CLICK",
  VideoDark = "VIDEO_DARK",
  AutoTrigger = "AUTO_TRIGGER",
  AllEvents = "ALL_EVENTS"
}

export const triggerEventData: Record<TriggerEventType, { color: string; label: string }> = {
  [TriggerEventType.TabChange]: { color: "#D2042D", label: "Tab Change" },
  [TriggerEventType.Reload]: { color: "#EE4B2B", label: "Reload" },
  [TriggerEventType.ButtonClick]: { color: "#f1c40f", label: "Button Click" },
  [TriggerEventType.VideoDark]: { color: "#880808", label: "Video Dark" },
  [TriggerEventType.AutoTrigger]: { color: "#2ecc71", label: "Auto Trigger" },
  [TriggerEventType.AllEvents]: { color: "#702963", label: "All Events" },
};

export const keyStrokes = {
  SPACE_KEY: ' ',
  ARROW_LEFT_KEY: 'ArrowLeft',
  ARROW_RIGHT_KEY: 'ArrowRight',
  ARROW_UP_KEY: 'ArrowUp',
  ARROW_DOWN_KEY: 'ArrowDown',
};